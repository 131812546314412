<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!posInformation">
      Agregar Caja
    </h1>

    <div v-if="!posInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave de la caja (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': posRequiredErrors.CLAVE }"
        :message="{
          'La clave de la caja no es valida': posRequiredErrors.CLAVE,
        }"
      >
        <b-input
          :disabled="posInformation"
          placeholder="Ejemplo: Caja principal"
          expanded
          icon="numeric"
          v-model="newPos.CLAVE"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción de la caja (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': posRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción de la caja no es valida':
            posRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Descripción de la caja principal"
          expanded
          icon="clipboard"
          v-model="newPos.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Almacen (Requerido)"
        expanded
        label-position="inside"
        required
        :type="{ 'is-danger': posRequiredErrors.ALMACEN }"
        :message="{
          'El almacén de la caja no es valido': posRequiredErrors.ALMACEN,
        }"
        class="required-input"
      >
        <b-select
          placeholder="Ejemplo: Martillo Almacen general"
          expanded
          icon="warehouse"
          v-model="newPos.ALMACEN"
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse._id"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Lista de precios (Requerido)"
        expanded
        label-position="inside"
        required
        class="required-input"
      >
        <b-taginput
          :data="priceLists"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="CLAVE_LISTAP"
          icon="format-list-bulleted"
          v-model="selectedPricesLists"
          @add="(value) => addPriceList(value)"
          @remove="(value) => removePriceList(value)"
          ellipsis
          placeholder="Listas de precios"
          aria-close-label="Borrar lista de precios seleccionada"
          check-infinite-scroll
        >
        </b-taginput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field label="Cajeros (Opcional)" expanded label-position="inside">
        <b-taginput
          v-model="newPos.USUARIOS"
          :data="getAllowedUser()"
          @remove="(value) => removeUser(value)"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="email"
          icon="account"
          placeholder="Asignar usuario"
        >
          <template #empty
            >No se encontraron usuarios sin cajas asignadas</template
          >
        </b-taginput>
      </b-field>
    </b-field>

    <b-field
      label="Cuenta contable de la caja (Opcional)"
      expanded
      label-position="inside"
      class="special-margin"
    >
      <b-input
        placeholder="Ejemplo: Descripción de la caja principal"
        expanded
        icon="clipboard"
        v-model="newPos.CUENTA_CONTABLE_CAJA"
      ></b-input>
    </b-field>

    <b-checkbox
      class="permissions-checkbox first-row-checkbox special-margin"
      true-value="S"
      false-value="N"
      v-model="newPos.DOS_PASOS"
    >
      Dividir venta en dos pasos
    </b-checkbox>

    <div class="add-global-controls" v-if="posInformation">
      <b-button type="is-success" @click="savePos(1)">Actualizar caja</b-button>
    </div>

    <div class="add-global-controls" v-if="!posInformation">
      <b-button type="is-success" @click="savePos(0)">Añadir caja</b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "@/utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddPos",
  props: ["posInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newPos: {
        CLAVE: "",
        DESCRIPCION: "",
        ALMACEN: "",
        LISTA_PRECIOS: [],
        USUARIOS: [],
        DOS_PASOS: "N",
        CUENTA_CONTABLE_CAJA: "",
      },
      posRequiredErrors: {
        CLAVE: false,
        DESCRIPCION: false,
        ALMACEN: false,
      },
      selectedPricesLists: [],
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.posInformation) {
      // Fill the form with received information
      fillObjectForm(this.posInformation, this.newPos);
      this.newPos._id = this.posInformation._id;
      this.newPos.ALMACEN = this.posInformation.ALMACEN._id;

      this.selectedPricesLists = [];
      if (this.posInformation.LISTA_PRECIOS.length > 0) {
        for (const singlePosPriceList of this.posInformation.LISTA_PRECIOS) {
          for (const singlePriceList of this.priceLists) {
            if (
              singlePosPriceList._id.toString() ===
              singlePriceList._id.toString()
            ) {
              this.selectedPricesLists.push(singlePriceList);
            }
          }
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves or updates brand in the database depending on type
     */
    async savePos(type) {
      try {
        this.posRequiredErrors = validateFields(
          this.newPos,
          this.posRequiredErrors
        );
        if (!this.checkValidPricesLists()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la caja en la base de datos, las listas de precios seleccioandas no son validad",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        if (checkValidationErrors(this.posRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEPOS" : "EDITPOS",
            this.newPos
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la caja",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar la caja en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la caja en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la caja en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkValidPricesLists() {
      return this.selectedPricesLists.length > 0;
    },
    getAllowedUser() {
      return this.users.filter((singleUser) => {
        return singleUser.allowAddPos;
      });
    },
    removeUser(value) {
      for (const [singleUserIndex, singleUser] of this.users.entries()) {
        if (singleUser.email === value.email) {
          this.users[singleUserIndex].allowAddPos = true;
          break;
        }
      }
    },
    addPriceList(priceList) {
      this.newPos.LISTA_PRECIOS.push(priceList._id);
    },
    removePriceList(priceList) {
      for (const [
        singlePriceListIndex,
        singlePriceList,
      ] of this.newPos.LISTA_PRECIOS.entries()) {
        if (singlePriceList._id.toString() === priceList._id.toString()) {
          this.newPos.LISTA_PRECIOS.splice(singlePriceListIndex, 1);
          return;
        }
      }
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        const singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    users() {
      return this.$store.getters.USERS.map((singleUser) => {
        const userWithProps = { ...singleUser };
        userWithProps.permissionsName = userWithProps.permissions.name;
        if (!userWithProps.pointOfSale) {
          userWithProps.allowAddPos = true;
        } else {
          userWithProps.allowAddPos = false;
        }
        return userWithProps;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
  watch: {
    priceLists() {
      this.selectedPricesLists = [];
      if (this.posInformation && this.posInformation.LISTA_PRECIOS.length > 0) {
        for (const singlePosPriceList of this.posInformation.LISTA_PRECIOS) {
          for (const singlePriceList of this.priceLists) {
            if (
              singlePosPriceList._id.toString() ===
              singlePriceList._id.toString()
            ) {
              this.selectedPricesLists.push(singlePriceList);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
